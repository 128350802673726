<template>
  <div
    class="fill-height"
    :class="{
      'root-dark': $vuetify.theme.dark,
      'root-light': !$vuetify.theme.dark
    }"
  >
    <div class="intro-background">
      <div class="wave-left d-none d-sm-flex"></div>
      <div class="wave-right d-none d-sm-flex"></div>
      <v-container
        class="d-flex flex-column px-6"
        :class="{
          'align-center sologan-desktop': $vuetify.breakpoint.smAndUp,
          'py-8 sologan-mobile': $vuetify.breakpoint.xs
        }"
      >
        <div
          :class="{
            'text-h2 text-center font-weight-black': $vuetify.breakpoint.smAndUp && vm.ido,
            'text-h4 text-center font-weight-black': $vuetify.breakpoint.smAndUp && !vm.ido,
            'text-h5 font-weight-bold': $vuetify.breakpoint.xs
          }"
        >
          <span
            :class="{
              'primary--text': true,
              'text-h2 font-weight-black': $vuetify.breakpoint.smAndUp,
              'text-h5 font-weight-bold': $vuetify.breakpoint.xs
            }"
            >BSClaunch<br
          /></span>
          <div class="my-4 font-weight-medium" v-if="vm.ido">
            Next-generation DEFI platform<br />
            with an Ecosystem launcher
          </div>
          <div class="my-4 font-weight-medium" v-else>
            B-Invest represents a complete breakthrough<br />
            for the future of users' rights to early investment
          </div>
          <div
            class="d-flex"
            :class="{
              'flex-row justify-center': $vuetify.breakpoint.smAndUp,
              'flex-column': $vuetify.breakpoint.xs
            }"
          >
            <v-btn to="/stake" color="primary" rounded>B-Stake </v-btn>
            <v-btn to="/allPools" rounded outlined :class="$vuetify.breakpoint.smAndUp ? 'mx-6' : 'my-4'">
              View all pools
            </v-btn>
            <v-btn to="/allAllocations" rounded outlined> Your allocations </v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <div class="primary lighten-1">
      <v-container class="pa-6 pb-16">
        <v-row>
          <!-- <v-col v-if="vm.hasUpcommingProjects" cols="12" class="text-h5 font-weight-bold">Upcoming Projects</v-col>
          <v-col cols="12" sm="6" md="4" v-for="model in vm.upcommingsProjects" :key="model.pool.id">
            <upcoming-project-item :model="model" />
          </v-col> -->
          <v-col v-if="vm.hasUpcommings" cols="12" class="text-h5 font-weight-bold">Upcoming Pools</v-col>
          <v-col cols="12" sm="6" md="4" v-for="model in vm.upcommingsPools" :key="model.pool.id">
            <upcoming-pool-item :model="model" />
          </v-col>
          <!-- <v-col v-if="vm.hasUpcommings" cols="12" class="d-flex justify-end">
            <v-btn color="primary" rounded outlined>Get Notified</v-btn>
          </v-col> -->
          <v-col v-if="vm.hasFeaturedPools" cols="12" class="text-h5 font-weight-bold">Featured Pools</v-col>
          <v-col cols="12" sm="6" md="4" v-for="model in vm.featuredPools" :key="model.pool.id">
            <featured-pool-item :model="model" />
          </v-col>
          <v-col v-if="vm.hasClosedPools" cols="12" class="text-h5 font-weight-bold">Closed Pools</v-col>
          <v-col cols="12" sm="6" md="4" v-for="model in vm.slicedClosedPools" :key="model.pool.id">
            <featured-pool-item :model="model" />
          </v-col>
        </v-row>
        <v-pagination
          class="mt-2"
          v-model="vm.currentPage"
          :total-visible="7"
          :length="vm.totalPage"
          @input="vm.changePage($event)"
          circle
        ></v-pagination>
      </v-container>
    </div>
    <company-footer />
  </div>
</template>

<script>
import FeaturedPoolItem from '../components/featured-pool-item.vue'
import UpcomingPoolItem from '../components/upcoming-pool-item.vue'
import UpcomingProjectItem from '../components/upcoming-project-item.vue'
import { IdoPoolsViewModel } from '../viewmodels/ido-pools-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    UpcomingPoolItem,
    FeaturedPoolItem,
    UpcomingProjectItem
  }
})
export default class IdoPools extends Vue {
  @Provide() vm = new IdoPoolsViewModel()

  @Watch('$route', { immediate: true }) onRouteChanged() {
    this.vm.loadData()
  }
}
</script>

<style scoped lang="scss">
.intro-background {
  position: relative;
  overflow: hidden;
}
.sologan-desktop {
  padding: 120px 0;
}
.wave-left {
  position: absolute;
  background: url('../../../assets/images/wave-background.dark.svg');
  left: -200px;
  bottom: -150px;
  width: 725px;
  height: 750px;
  overflow: hidden;
}
.wave-right {
  position: absolute;
  background: url('../../../assets/images/wave-background.dark.svg');
  top: -100px;
  right: -175px;
  width: 725px;
  height: 750px;
  overflow: hidden;
  transform: rotate(180deg);
}
.root-light {
  .wave-left {
    background: url('../../../assets/images/wave-background.light.svg');
  }
  .wave-right {
    background: url('../../../assets/images/wave-background.light.svg');
  }
}
</style>
