var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",class:{
    'root-dark': _vm.$vuetify.theme.dark,
    'root-light': !_vm.$vuetify.theme.dark
  }},[_c('div',{staticClass:"intro-background"},[_c('div',{staticClass:"wave-left d-none d-sm-flex"}),_c('div',{staticClass:"wave-right d-none d-sm-flex"}),_c('v-container',{staticClass:"d-flex flex-column px-6",class:{
        'align-center sologan-desktop': _vm.$vuetify.breakpoint.smAndUp,
        'py-8 sologan-mobile': _vm.$vuetify.breakpoint.xs
      }},[_c('div',{class:{
          'text-h2 text-center font-weight-black': _vm.$vuetify.breakpoint.smAndUp && _vm.vm.ido,
          'text-h4 text-center font-weight-black': _vm.$vuetify.breakpoint.smAndUp && !_vm.vm.ido,
          'text-h5 font-weight-bold': _vm.$vuetify.breakpoint.xs
        }},[_c('span',{class:{
            'primary--text': true,
            'text-h2 font-weight-black': _vm.$vuetify.breakpoint.smAndUp,
            'text-h5 font-weight-bold': _vm.$vuetify.breakpoint.xs
          }},[_vm._v("BSClaunch"),_c('br')]),(_vm.vm.ido)?_c('div',{staticClass:"my-4 font-weight-medium"},[_vm._v(" Next-generation DEFI platform"),_c('br'),_vm._v(" with an Ecosystem launcher ")]):_c('div',{staticClass:"my-4 font-weight-medium"},[_vm._v(" B-Invest represents a complete breakthrough"),_c('br'),_vm._v(" for the future of users' rights to early investment ")]),_c('div',{staticClass:"d-flex",class:{
            'flex-row justify-center': _vm.$vuetify.breakpoint.smAndUp,
            'flex-column': _vm.$vuetify.breakpoint.xs
          }},[_c('v-btn',{attrs:{"to":"/stake","color":"primary","rounded":""}},[_vm._v("B-Stake ")]),_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndUp ? 'mx-6' : 'my-4',attrs:{"to":"/allPools","rounded":"","outlined":""}},[_vm._v(" View all pools ")]),_c('v-btn',{attrs:{"to":"/allAllocations","rounded":"","outlined":""}},[_vm._v(" Your allocations ")])],1)])])],1),_c('div',{staticClass:"primary lighten-1"},[_c('v-container',{staticClass:"pa-6 pb-16"},[_c('v-row',[(_vm.vm.hasUpcommings)?_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"12"}},[_vm._v("Upcoming Pools")]):_vm._e(),_vm._l((_vm.vm.upcommingsPools),function(model){return _c('v-col',{key:model.pool.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('upcoming-pool-item',{attrs:{"model":model}})],1)}),(_vm.vm.hasFeaturedPools)?_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"12"}},[_vm._v("Featured Pools")]):_vm._e(),_vm._l((_vm.vm.featuredPools),function(model){return _c('v-col',{key:model.pool.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('featured-pool-item',{attrs:{"model":model}})],1)}),(_vm.vm.hasClosedPools)?_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"12"}},[_vm._v("Closed Pools")]):_vm._e(),_vm._l((_vm.vm.slicedClosedPools),function(model){return _c('v-col',{key:model.pool.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('featured-pool-item',{attrs:{"model":model}})],1)})],2),_c('v-pagination',{staticClass:"mt-2",attrs:{"total-visible":7,"length":_vm.vm.totalPage,"circle":""},on:{"input":function($event){return _vm.vm.changePage($event)}},model:{value:(_vm.vm.currentPage),callback:function ($$v) {_vm.$set(_vm.vm, "currentPage", $$v)},expression:"vm.currentPage"}})],1)],1),_c('company-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }